import Software1 from 'assets/images/Offer/software1.png';
import Software2 from 'assets/images/Offer/software2.png';
import Software3 from 'assets/images/Offer/software3.png';
import Software4 from 'assets/images/Offer/software4.png';
import Software5 from 'assets/images/Offer/software5.png';
import SoftwarePaymentIntegration from 'assets/images/Offer/softwarePaymentIntegration.png';
import Deep1 from 'assets/images/Offer/deep1.png';
import Deep2 from 'assets/images/Offer/deep2.png';
import Deep3 from 'assets/images/Offer/deep3.png';
import Deep6 from 'assets/images/Offer/deep6.png';
import Staff1 from 'assets/images/Offer/staff1.png';
import Staff2 from 'assets/images/Offer/staff2.png';
import Staff3 from 'assets/images/Offer/staff3.png';
import Staff4 from 'assets/images/Offer/staff4.png';
import Hardware1 from 'assets/images/Offer/hard1.png';
import Hardware2 from 'assets/images/Offer/hard2.png';
import Hardware3 from 'assets/images/Offer/hard3.png';
import Hardware4 from 'assets/images/Offer/hard4.png';
import Deep7 from 'assets/images/GPT/gpt-notebook.png';

export const softwareData = [
  {
    imgSrc: Software1,
    title: 'Web Apps',
    desc: `Benefit from full-cycle web development services – from idea to a full-featured app with 24/7 support and maintenance. We work on turnkey projects of varying complexity, using the latest technologies and frameworks.`,
    descSecondary: `Our engineers can implement different kinds of application architecture, including microservices, serverless, headless, cloud-native, or progressive web apps.`,
    href: '/contact',
  },
  {
    imgSrc: Software2,
    title: 'Mobile Apps',
    desc: `Create a native iOS or Android application that requires complex logic with Codahead. We can build MVP for you as well as do technical assessment of the existing solution.`,
    descSecondary: `We're experienced with integrations with 3rd parties including payment gateways.`,
    href: '/contact',
  },
  {
    imgSrc: Software3,
    title: 'Admin panels with advanced data analytics',
    desc: `Codahead team can help you build a customer-centric commerce platform to create an exceptional digital customer experience, underline the value of your offering, and boost online earnings giving you insight into what your customers do.`,
    href: '/contact',
  },
  {
    imgSrc: SoftwarePaymentIntegration,
    title: 'Payment integration',
    desc: `At Codahead, we lead seamless payment integration, specializing in customized solutions, rigorous testing, and unwavering support. From PCI compliance to programming diverse hardware, we ensure secure payment acceptance via RFiD, BLE, or NFC.`,
    descSecondary: `Our expertise includes successful gateway integrations with Braintree, Authorize.net, Stripe, ApplePay, and others, alongside top loyalty card providers like GiveX.`,
    href: '/contact',
  },
  {
    imgSrc: Software4,
    title: 'UX / UI design',
    desc: `Let your product speak for itself with a modern, clean look and intuitive interface. Our designer can improve the usability of your software and enhance users’ engagement with UX/UI best practices.`,
    descSecondary: `We design products for our clients.`,
    href: '/contact',
  },
  {
    imgSrc: Software5,
    title: 'Quality Assurance',
    desc: `Quality Assurance goes beyond just testing—it’s about securing user satisfaction, optimizing functionality, and minimizing risks.`,
    descSecondary: `Our team’s commitment to excellence drives rigorous, end-to-end QA that ensures your software is flawless and ready for success`,
    href: '/contact',
  },
];

export const deepTechData = [
  {
    imgSrc: Deep1,
    title: 'Machine learning',
    desc: `Drive your business agility and productivity with Artificial Intelligence and Machine Learning services.`,
    descSecondary: `Our team applies in-depth AI/ML expertise to help you leverage the full value of intelligent automation technologies.`,
    href: '/contact',
  },
  {
    imgSrc: Deep2,
    title: 'Data sets & Neural networks training',
    desc: `Codahead can help you with the data by preparing datasets and content indexing for the specific need and train the neural network for you on our internal infrastructure .`,
    href: '/contact',
  },
  {
    imgSrc: Deep3,
    title: 'Image AI processing',
    desc: `With our expertise in computer vision, we bring image recognition, facial authentication, biometrics, and object detection to your business. Enhance customer experiences and security with cutting-edge solutions.`,
    href: '/contact',
  },
  {
    imgSrc: Deep6,
    title: 'Sound and Voice AI processing',
    desc: `Imagine using voice recognition to streamline customer support, or sound AI to gather insights from calls and interactions. We make it easy to integrate smart voice solutions that improve efficiency and elevate the customer experience.`,
    href: '/contact',
  },
  {
    imgSrc: Deep7,
    title: 'GPT solutions',
    desc: `From automating customer support to generating content, our GPT-powered solutions help you streamline processes and deliver smarter, faster results. Let AI handle the heavy lifting while you focus on growing your business.
Want to see it in action?`,
    href: '/contact',
    to: '/gpt',
  },
];

export const hardwareData = [
  {
    imgSrc: Hardware1,
    title: 'Electronics design',
    desc: `Codahead delivers electronic product designs—including hardware and software device solutions. `,
    descSecondary: `Having experience and technical expertise we provide electronic design services including PCB layouts, providing EMC compliance and design it for the manufacturing after tests and production preparation.\n\nOur in house design area is equipped for variety of designs.`,
    href: '/contact',
  },
  {
    imgSrc: Hardware2,
    title: 'Hardware prototyping',
    desc: `With IoT devices, hardware and software development should go hand in hand. Codahead will work with you to fully understand your hardware requirements and how they would impact our framework choices.`,
    descSecondary: `Keeping your requirements in mind, we’ll make recommendations and build out a prototype to use for testing. Once we’ve worked out the kinks, we can put the hardware into production. Modestly speaking, whenever we bring an Internet of Things device to life, it is top-notch.`,
    href: '/contact',
  },
  {
    imgSrc: Hardware3,
    title: 'Firmware & embedded software development',
    desc: `Codahead can take care of any IoT project and build embedded firmware, human-machine interfaces.\nOur modern software technologies, tool sets, and best practices—such as comprehensive testing and continuous integration—have been tested over a number of projects that we delivered to our partners.\nOur embedded software engineers will leverage C/C++, Python, Shell, QML, Assembler, Lua and create for you design documentation, specification and build the prototype.`,
    href: '/contact',
  },
  {
    imgSrc: Hardware4,
    title: 'Wireless connectivity',
    desc: `Codahead will provide your product with reliable connectivity as we integrate Wi-Fi, Bluetooth, RFiD, NFC, mobile data and other low-energy wireless solutions so you're able to exchange data in a secure manner as keep the data protection on top of the priorities.`,
    href: '/contact',
  },
];

export const staffAugmentationData = [
  {
    imgSrc: Staff1,
    title: 'Managed teams',
    desc: `Staff augmentation lets you bring in the right talent for the job, without the hassle of recruitment. Whether you're dealing with peak workloads, short-term projects, or long-term growth, we match you with skilled professionals who fit your needs. This flexible solution gives you the expertise you need, exactly when you need it.`,
    href: '/contact',
  },
  {
    imgSrc: Staff2,
    title: 'Oursourcing',
    desc: `Have your own dedicated team of developers, QAs, managers, designers or hardware developers. `,
    descSecondary: `If you know what to do and how to achieve it, but don't have the resources, look no further.\nWe fit your culture and adapt to your time zone.`,
    href: '/contact',
  },
  {
    imgSrc: Staff3,
    title: 'US offshore software development',
    desc: `Our remote, US-based offshore team helps you scale efficiently, providing the expertise you need to build powerful software solutions. We integrate smoothly with your team to deliver the best results without the overhead of traditional hiring.`,
    href: '/contact',
  },
  {
    imgSrc: Staff4,
    title: 'Development for startups',
    desc: `At Codahead, we understand the challenges startups face. 
Our expert team builds customized software solutions that are fast, reliable, and scalable, helping your business launch, grow, and thrive. Let’s turn your ideas into a successful product!`,
    href: '/contact',
  },
];
